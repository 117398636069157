.thx
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center

@media screen and ( max-width:768px  )
    .thx
        h1
            margin: 2rem 2rem
        h3
            margin: 2rem 2rem 1rem 2rem
        h4
            margin: 0rem 2rem
