*
  margin: 0
  padding: 0
  box-sizing: border-box !important

html, body
  height: 100%
  font-family: 'Montserrat', sans-serif
  color: white
  overflow-x: hidden
  scroll-behavior: smooth

button
  cursor: pointer

p
  font-size: 1.25rem

h1
  font-family: 'Karla', sans-serif
  font-size: 4rem
  font-weight: 800

h2
  font-family: 'Karla', sans-serif
  font-size: 3rem
  font-weight: 800

h3
  font-family: 'Karla', sans-serif
  font-size: 2.5rem

h4
  font-family: 'Karla', sans-serif
  font-size: 2rem

h5
  font-family: 'Karla', sans-serif
  font-size: 1.5rem

.gradient-text
  background: linear-gradient(90deg, #d6dce2, #064775)
  width: fit-content
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.gm-sec
  position: relative
  z-index: 1
  background: rgba( 255, 255, 255, 0.10 )
  background: -moz-linear-gradient(90deg, rgba(29,34,69, .98) 0%, rgba(29,34,69, .98) 100%)
  box-shadow: 0 8px 32px 0 #1f43875e
  backdrop-filter: blur( 14.0px )
  -webkit-backdrop-filter: blur( 14.0px )
  border-radius: 5px
  border: 1px solid rgba( 255, 255, 255, 0.05 )

.gm-disabled
  position: relative
  z-index: 1
  pointer-events: none
  cursor: auto
  background: rgba(93, 93, 93, 0.7)
  box-shadow: 0 8px 32px 0 #6161625e
  backdrop-filter: blur( 7.0px )
  -webkit-backdrop-filter: blur( 7.0px )
  border-radius: 5px
  border: 1px solid rgba( 255, 255, 255, 0.05 )
  color: #dad8d8
  &:hover
    background: rgba(93, 93, 93, 0.7) !important

.gm
  position: relative
  z-index: 1
  background: rgba( 255, 255, 255, 0.2 )
  background: -moz-linear-gradient(90deg, rgba(28,30,55, 1) 0%, rgba(28,30,55, 1) 100%)
  box-shadow: 0 8px 32px 0 #1f43875e
  backdrop-filter: blur( 7.0px )
  -webkit-backdrop-filter: blur( 7.0px )
  border-radius: 5px
  border: 1px solid rgba( 255, 255, 255, 0.05 )

.btn-alt
  background: rgb(0,240,255)
  background: linear-gradient(90deg, rgba(0,240,255,1) 0%, rgba(0,151,222,1) 100%)
  border: none
  font-family: 'Karla', sans-serif !important
  font-weight: 800
  font-size: 1rem
  color: #f3f3f3 !important
  &:hover
    background: linear-gradient(90deg, #a2f9ff 0%, #8bd3f5 100%) !important

.btn-wa
  background: rgb(18,143,125)
  background: -moz-linear-gradient(270deg, rgba(18,143,125,1) 0%, rgba(0,230,118,1) 100%)
  background: -webkit-linear-gradient(270deg, rgba(18,143,125,1) 0%, rgba(0,230,118,1) 100%)
  background: linear-gradient(270deg, rgba(18,143,125,1) 0%, rgba(0,230,118,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#128f7d",endColorstr="#00e676",GradientType=1)
  border: none
  font-family: 'Karla', sans-serif !important
  font-weight: 800
  font-size: 1rem
  color: #363636 !important
  &:hover
    background: linear-gradient(90deg, rgba(18,143,125,1) 0%, rgba(0,230,118,1) 100%)

.display-none
  display: none

// Usefull
.pt-1
  padding-top: 1rem

.pt-2
  padding-top: 2rem

.pt-3
  padding-top: 3rem

.pt-4
  padding-top: 4rem

.pt-5
  padding-top: 5rem

.pb-1
  padding-bottom: 1rem

.pb-2
  padding-bottom: 2rem

.mt-1
  margin-top: 1rem

.mt-2
  margin-top: 2rem

.mt-3
  margin-top: 3rem

.mt-4
  margin-top: 4rem

.mt-5
  margin-top: 5rem

.ml-1
  margin-left: 1rem

.ml-2
  margin-left: 2rem

.ml-3
  margin-left: 3rem

.ml-4
  margin-left: 4rem

.ml-5
  margin-left: 5rem

//Buttons

.btn
  border-radius: 6px
  min-width: 100px
  min-height: 40px
  padding: 12px 20px
  color: white
  font-size: 1.25rem
  font-family: 'Montserrat', sans-serif
  text-decoration: none
  &:hover
    background: rgba( 255, 255, 255, 0.3 )

// Background

.background
  display: table
  width: 100vw
  height: 100vh
  background: linear-gradient(90deg, rgba(4,10,55,1) 0%, rgba(1,3,20,1) 100%)
  color: #000
  line-height: 1.6
  position: fixed
  font-family: sans-serif
  overflow: hidden
  z-index: -2

.lines
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 100%
  margin: auto
  width: 90vw

.line
  position: absolute
  width: 1px
  height: 100%
  top: 0
  left: 50%
  background: rgba(23,23,23,0.1)
  overflow: hidden

  &::after
    content: ''
    display: block
    position: absolute
    height: 45vh
    width: 100%
    top: -50%
    left: 0
    background: linear-gradient(to bottom, rgba(4, 92,193, 0) 0%, #045C85 75%, #045C85 100%)
    animation: drop 10s 0s infinite

    animation-fill-mode: forwards
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97)

  &:nth-child(1)
    margin-left: -45%
    &::after
      animation-delay: 8.2s

  &:nth-child(2)
    margin-left: -35%
    &::after
      animation-delay: 6.2s

  &:nth-child(3)
    margin-left: -25%
    &::after
      animation-delay: 3.5s

  &:nth-child(4)
    margin-left: -15%
    &::after
      animation-delay: 1.2s

  &:nth-child(5)
    margin-left: 0%
    &::after
      animation-delay: 0s

  &:nth-child(6)
    margin-left: 15%
    &::after
      animation-delay: 2.4s

  &:nth-child(7)
    margin-left: 25%
    &::after
      animation-delay: 4.9s
  &:nth-child(8)
    margin-left: 35%
    &::after
      animation-delay: 7.1s

  &:nth-child(9)
    margin-left: 50%
    &::after
      animation-delay: 9.0s

@keyframes drop
  0%
    top: -50%
  100%
    top: 110%

//Footer
.footer
  p
    margin: 5rem 6rem 5rem 6rem
    font-size: 2.4vw

.footer-div
  width: 100%
  height: 3rem
  display: flex
  justify-content: center
  align-items: center
  p
    margin: 0
    font-size: 1.5vw

// ----- footer end
.popup
  z-index: 3
  top: 30vh
  left: 30vw
  width: 40vw
  height: 40vh
  margin: auto
  display: flex
  flex-direction: column
  justify-content: center
  align-content: center
  position: absolute
  h3
    text-align: center
  p
    text-align: center
  button
    width: 40%
    margin-left: 50%
    transform: translateX(-50%)
  // background-color: white 

// Scrollbar
::-webkit-scrollbar
  width: 8px
  height: 8px

::-webkit-scrollbar-button
  width: 0px
  height: 0px

::-webkit-scrollbar-thumb
  background: #597ca1
  border: 0px none #ffffff
  border-radius: 4px

::-webkit-scrollbar-thumb:hover
  background: #597ca1

::-webkit-scrollbar-thumb:active
  background: #597ca1

::-webkit-scrollbar-track
  background: #30818b36
  border: 0px none #ffffff
  border-radius: 2px

::-webkit-scrollbar-track:hover
  background: #666666

::-webkit-scrollbar-track:active
  background: #333333

::-webkit-scrollbar-corner
  background: transparent

@media screen and (max-width: 768px)
  p
    font-size: 1rem

  h1
    font-family: 'Karla', sans-serif
    font-size: 3.5rem

  h2
    font-family: 'Karla', sans-serif
    font-size: 2.5rem
    font-weight: 800

  h3
    font-family: 'Karla', sans-serif
    font-size: 2rem

  h4
    font-family: 'Karla', sans-serif
    font-size: 1.5rem

  h5
    font-family: 'Karla', sans-serif
    font-size: 1.25rem

  .footer
    p
      margin: 4rem 1rem 1rem 1rem
      font-size: 6vw

  .footer-div
    width: 100%
    height: 3rem
    display: flex
    justify-content: center
    align-items: center
    p
      margin: 0
      font-size: 3.5vw
