@import './mixin.scss';

#form_AG {
  z-index: 5;
  top: 13vh;
  left: 30vw;
  width: 40vw;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  h3 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  button {
    width: 40%;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .wa-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }



  * {
    outline: 0;
  }
  input[type="text"] {
    padding: 6px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: rgb(255, 255, 255) !important;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  input[type="date"] {
    padding: 6px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: rgb(255, 255, 255) !important;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  textarea {
    padding: 6px;
    height: auto;
    border: #979797 1px solid;
    border-radius: 4px;
    color: #000 !important;
    font-size: 14px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
  }
  ._submit {
    -webkit-appearance: none;
    cursor: pointer;
    font-family: arial, sans-serif;
    font-size: 14px;
    text-align: center;
    background: #333 !important;
    border: 0 !important;
    -moz-border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
    color: #fff !important;
    padding: 10px !important;
  }
  ._close-icon {
    cursor: pointer;
    background-image: url("https://d226aj4ao1t61q.cloudfront.net/esfkyjh1uforms-close-dark.png");
    background-repeat: no-repeat;
    background-size: 14.2px 14.2px;
    position: absolute;
    display: block;
    top: 11px;
    right: 9px;
    overflow: hidden;
    width: 16.2px;
    height: 16.2px;
    &:before {
      position: relative;
    }
  }
  .form-error{
    background-color: rgba(156, 16, 16, 0.534);
    color: #fff;
    border-radius: 4px;
  }

  .form-resp{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    h2{
      text-align: center;
      font-size: 3vw;
      margin: 1rem 2rem;
    }
    p{
      margin: 0rem 6rem 1rem 6rem;
      font-size: 1vw;
    }
  }

  .form-body {
    margin-bottom: 30px;
  }
  .form-image-left {
    width: 150px;
    float: left;
  }
  .form-content-right {
    margin-left: 164px;
  }
  .form-branding {
    color: #fff;
    font-size: 10px;
    clear: both;
    text-align: left;
    margin-top: 30px;
    font-weight: 100;
    ._logo {
      display: block;
      width: 130px;
      height: 14px;
      margin-top: 6px;
      background-image: url("https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png");
      background-size: 130px auto;
      background-repeat: no-repeat;
    }
  }
  .form-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  .form-close-btn {
    z-index: 6;
    border-radius: 50%;
    background: rgb(143, 41, 79);
    background: radial-gradient(
      circle,
      rgba(143, 41, 79, 1) 100%,
      rgba(222, 0, 113, 1) 0%
    );
    width: 70px;
    height: 70px;
    position: absolute;
    top: -25px;
    right: -60px;
    border: none;
    color: #fff;
    font-size: 40px;
    transition: all 0.5s ease;
    &:hover {
      background: rgb(190, 81, 121);
      background: radial-gradient(
        circle,
        rgb(187, 77, 117) 100%,
        rgb(246, 69, 161) 0%
      );
      width: 80px;
      height: 80px;
      top: -35px;
      right: -70px;
    }
  }
  .form_element {
    .form-label {
      font-weight: bold;
      margin-bottom: 5px;
      display: block;
    }
    position: relative;
    margin-bottom: 10px;
    font-size: 0;
    max-width: 100%;
    * {
      font-size: 14px;
    }
    input[type="text"] {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    input[type="date"] {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    select {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    textarea {
      &:not(.g-recaptcha-response) {
        display: block;
        width: 100%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
    img.form-image {
      max-width: 100%;
    }
  }
  .form_element._clear {
    clear: both;
    width: 100%;
    float: none;
    &:after {
      clear: left;
    }
  }
  ._field-wrapper {
    position: relative;
  }
  ._inline-style {
    float: left;
    input[type="text"] {
      width: 150px;
    }
    &:not(._clear) {
      + {
        ._inline-style {
          &:not(._clear) {
            margin-left: 20px;
          }
        }
      }
    }
  }
  ._clear-element {
    clear: left;
  }
  ._full_width {
    width: 100%;
  }
  .form_full_field {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  input[type="text"]._has_error {
    border: #f37c7b 1px solid;
  }
  textarea._has_error {
    border: #f37c7b 1px solid;
  }
  input[type="checkbox"]._has_error {
    outline: #f37c7b 1px solid;
  }
  ._error {
    display: block;
    position: absolute;
    font-size: 14px;
    z-index: 10000001;
  }
  ._error._above {
    padding-bottom: 4px;
    bottom: 39px;
    right: 0;
    ._error-arrow {
      bottom: 0;
      right: 15px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #f37c7b;
    }
  }
  ._error._below {
    padding-top: 4px;
    top: 100%;
    right: 0;
    ._error-arrow {
      top: 0;
      right: 15px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #f37c7b;
    }
  }
  ._error-inner {
    padding: 8px 12px;
    background-color: #f37c7b;
    font-size: 14px;
    font-family: arial, sans-serif;
    color: #fff;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }
  ._error-inner.form_error {
    margin-bottom: 5px;
    text-align: left;
  }
  ._button-wrapper {
    ._error-inner.form_error {
      position: static;
    }
  }
  ._error-inner._no_arrow {
    margin-bottom: 10px;
  }
  ._error-arrow {
    position: absolute;
    width: 0;
    height: 0;
  }
  ._error-html {
    margin-bottom: 10px;
  }
  input[type="text"].datetime_date {
    width: 69%;
    display: inline;
  }
  select.datetime_time {
    width: 29%;
    display: inline;
    height: 32px;
  }
  .form-title {
    font-family: "Karla", sans-serif;
    font-size: 3.2vw;
    line-height: auto;
    font-weight: 800;
    margin-bottom: 0;
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: center;
  }
  .form-description {
    font-family: "Montserrat", sans-serif;
    font-size: 1.7vw;
    line-height: auto;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  .form-thank-you {
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 18px;
  }
}

.form_img {
  height: 100px;
  // width: 20px;
  img {
    height: 100%;
    // width: 100%;
  }
}

.form_element-flex {
  display: flex;
  margin-left: 10%;
  margin-top: 1rem;
  label {
    width: 20%;
    font-size: 1.5vw !important;
    font-family: "Karla", sans-serif !important;
  }
  ._field-wrapper {
    width: 60%;
    input {
      border: 1px solid rgba(69, 145, 165, 0.2) !important;
      color: white !important;
    }
    input::placeholder {
      color: rgb(173, 173, 173);
    }
  }
}
.form_hide {
  display: none;
  visibility: hidden;
}
.form_show {
  display: block;
  visibility: visible;
}
#form_AG.form-top {
  top: 0;
}
#form_AG.form-bottom {
  bottom: 0;
}
#form_AG.form-left {
  left: 0;
}
#form_AG.form-right {
  right: 0;
}
#form_AG._dark {
  .form-branding {
    color: #333;
    ._logo {
      background-image: url("https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png");
    }
  }
}
.pika-single {
  z-index: 10000001 !important;
}
#form_AG._inline-form {
  font-family: "IBM Plex Sans", Helvetica, sans-serif, "IBM Plex Sans", arial,
    sans-serif;
  .form-content {
    font-family: "IBM Plex Sans", Helvetica, sans-serif, "IBM Plex Sans", arial,
      sans-serif;
  }
  input {
    font-family: "IBM Plex Sans", Helvetica, sans-serif, "IBM Plex Sans", arial,
      sans-serif;
  }
  ._submit {
    font-family: "IBM Plex Sans", Helvetica, sans-serif, "IBM Plex Sans", arial,
      sans-serif;
  }
}
#form_AG._inline-style {
  width: auto;
  display: inline-block;
  input[type="text"] {
    padding: 10px 12px;
  }
  input[type="date"] {
    padding: 10px 12px;
  }
  button._inline-style {
    position: relative;
    top: 27px;
  }
  p {
    margin: 0;
  }
  ._button-wrapper {
    position: relative;
    margin: 27px 12.5px 0 20px;
  }
}
@media screen and (max-width: 768px) {
  
  #form_AG {
    z-index: 3;
    top: 12vh;
    left: 5vw;
    width: 90vw;
    height: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: absolute;
    overflow: hidden;
  }
  .form-title {
    font-size: 10vw !important;

    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .form-description {
    font-size: 5vw !important;
    margin-bottom: 0;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .form_element-flex {
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    margin-top: 1rem;
    label {
      width: 20%;
      font-size: 5vw !important;
      font-family: "Karla", sans-serif !important;
    }
    ._field-wrapper {
      width: 80%;
      input {
        border: 1px solid rgba(69, 145, 165, 0.2) !important;
        color: white !important;
      }
      input::placeholder {
        color: rgb(173, 173, 173);
      }
    }
  }
  .form-close-btn {
    z-index: 4;
    border-radius: 50%;
    background: rgb(143, 41, 79);
    background: radial-gradient(
      circle,
      rgba(143, 41, 79, 1) 100%,
      rgba(222, 0, 113, 1) 0%
    );
    width: 40px !important;
    height: 40px !important;
    position: absolute;
    top: 6px !important;
    right: -12px !important;
    border: none;
    color: #fff;
    font-size: 26px !important;
    transition: all 0.5s ease;
	-webkit-box-shadow: 1px 1px 5px 1px rgba(1, 17, 31, 1);
	box-shadow: 1px 1px 5px 1px rgba(1, 17, 31, 1);
    &:hover {
      background: rgb(190, 81, 121);
      background: radial-gradient(
        circle,
        rgb(187, 77, 117) 100%,
        rgb(246, 69, 161) 0%
      );
      width: 80px;
      height: 80px;
      top: -35px;
      right: -70px;
    }
  }
}

@media screen and (max-width: 1023px){
  @include orientation(landscape){
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    #form_AG {
      z-index: 3;
      top: 22vh;
      left: 5vw;
      width: 90vw;
      height: auto;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      position: absolute;
      overflow: hidden;
    }
    .form-title {
      font-size: 5.5vw !important;
  
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .form-description {
      font-size: 2.5vw !important;
      margin-bottom: 0;
      margin-left: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
    .form_element-flex {
      display: flex;
      flex-direction: column;
      margin-left: 10%;
      margin-top: 1rem;
      label {
        width: 20%;
        font-size: 3vw !important;
        font-family: "Karla", sans-serif !important;
      }
      ._field-wrapper {
        width: 80%;
        input {
          border: 1px solid rgba(69, 145, 165, 0.2) !important;
          color: white !important;
        }
        input::placeholder {
          color: rgb(173, 173, 173);
        }
      }
    }
    .form-close-btn {
      z-index: 4;
      border-radius: 50%;
      background: rgb(143, 41, 79);
      background: radial-gradient(
        circle,
        rgba(143, 41, 79, 1) 100%,
        rgba(222, 0, 113, 1) 0%
      );
      width: 40px !important;
      height: 40px !important;
      position: absolute;
      top: 6px !important;
      right: -12px !important;
      border: none;
      color: #fff;
      font-size: 26px !important;
      transition: all 0.5s ease;
    -webkit-box-shadow: 1px 1px 5px 1px rgba(1, 17, 31, 1);
    box-shadow: 1px 1px 5px 1px rgba(1, 17, 31, 1);
      &:hover {
        background: rgb(190, 81, 121);
        background: radial-gradient(
          circle,
          rgb(187, 77, 117) 100%,
          rgb(246, 69, 161) 0%
        );
        width: 80px;
        height: 80px;
        top: -35px;
        right: -70px;
      }
    }
  }
}
    
